<template>
  <div class="su-view su-about">
    {{ isLoading }}
    <div class="users-body" v-if="loading">
      <Loader/>
    </div>
    <div class="su-content su-about-content" v-else>
      <div class="su-about-info" v-if="user">
        <!-- КОНТЕЙНЕР -->
        <div class="su-body">
          <!-- ЛЕВАЯ КОЛОНКА -->
          <div class="su-leftside">

            <!-- ЗАГОЛОВОК -->
            <div class="su-body__title">
              <p>
                Сведения о пользователе
              </p>
            </div>

            <!-- АВАТАР -->
            <div class="su-body__avatar" v-if="user.avatar">

              <!-- АВАТАР НЕАКТИВНОГО ПОЛЬЗОВАТЕЛЯ -->
              <div class="su-body__avatar" v-if="!user.isActive">
                <img
                    :src="user.avatar.base64Preview"
                    alt="avatar-inactive-user"
                    class="avatar-image"
                    v-if="user.avatar"
                >
                <img src="@/assets/images/avatar.svg" alt="avatar" class="avatar-image" v-else>
              </div>

              <!-- АВАТАР АКТИВНОГО ПОЛЬЗОВАТЕЛЯ -->
              <div class="su-body__avatar " :class="[
                    user.status === userStatusesEnum.APPROVED && ( user.updateStatus === updateStatusesEnum.INITIAL ||  user.updateStatus === updateStatusesEnum.APPROVED)
                    ? 'accept' : '',
                    user.status === userStatusesEnum.DECLINED || user.status === userStatusesEnum.APPROVED && user.updateStatus === updateStatusesEnum.DECLINED
                    ? 'attent' : ''
                ]" v-else>
                <template v-if="user.avatar && user.avatar.updateStatus === 1">
                  <span class="avatar-update">
                    Обновлено
                  </span>
                  <img
                      :src="user.avatar.base64Preview"
                      alt="avatar-updated"
                      class="avatar-image"
                      v-if="user.avatar.base64Preview">
                  <img src="@/assets/images/avatar.svg" alt="avatar" class="avatar-image" v-else>
                </template>
                <template v-else>
                  <img
                      :src="user.avatar.base64Preview"
                      alt="avatar"
                      class="avatar-image"
                      v-if="user.avatar"
                  >
                  <img src="@/assets/images/avatar.svg" alt="avatar" class="avatar-image" v-else>
                </template>
              </div>
            </div>

            <!-- ЗАГЛУШКА ДЛЯ АВАТАРА -->
            <div class="su-body__avatar" v-else :style="getUserAvatarStyle(user)">
              {{ getInitials(user) }}
            </div>

            <!-- ОСНОВАНАЯ ИНФОРМАЦИЯ -->
            <div class="su-body__block-wrap">
              <div class="su-body__block">

                <div class="su-body__block-title">
                  <!--  TODO  PROMOCODE -->
                  <p>
                    Подписка на услуги сервиса {{ subscriptionPaidAt ? "" : "отсутствует" }}
                  </p>

                </div>
                <template v-if="subscriptionPaidAt">
                  <div class="su-body__block-descr">
                    <p>
                      Дата оплаты:
                    </p>
                    <p>
                      {{
                        getDateTime(subscriptionPaidAt)
                      }}
                    </p>
                  </div>
                  <div class="su-body__block-descr">
                    <p>
                      Дата следующего платежа:
                    </p>
                    <p>
                      {{
                        subscriptionNextPaymentDt ?
                            getDateTime(subscriptionNextPaymentDt) :
                            "отсутствует"
                      }}
                    </p>
                  </div>
                </template>

                <div class="row ai-center">
                  <input
                      class="sorting__input"
                      type="checkbox"
                      id="subscriptions-disabled"
                      v-model="user.subscriptionsDisabled"
                      @change="reverseSubscriptionsSettings(user.id)"
                      name=""
                  >
                  <label for="subscriptions-disabled">
                    Не проверять наличие подписки
                  </label>
                </div>
                <!-- Галочка "Макретплейс" -->
                <div class="row ai-center">
                  <input
                      class="sorting__input"
                      type="checkbox"
                      id="is-marketplace"
                      v-model="user.isMarketplace"
                      @change="reverseMarketplaceSettings(user.id)"
                      name=""
                  >
                  <label for="is-marketplace">
                    Является маркетплейсом
                  </label>
                </div>


                <div class="su-body__block-title">
                  <p>
                    Регистрационные данные
                  </p>
                </div>

                <!-- v ДОКУМЕНТ НА ОПЛАТУ v ДОЛЖЕН БЫТЬ В ОБЩЕМ СПИСКЕ ДОКУМЕНТОВ ДО-->
                <!--                <template v-for="(doc, idx) in documentsList">-->
                <!--                  <div class="row" v-if="doc.documentType === documentTypesEnum.CEO_CHECK_PAYMENT" :key="idx">-->
                <!--                    <span v-show="noDocTypeSix"> {{ noDocTypeSix = false }}</span>-->
                <!--                    <div class="row ai-center jc-between">-->
                <!--                      <div class="row__string" v-if="doc.file"-->
                <!--                           :class="doc.updateStatus === 0 || doc.updateStatus === 3 ? 'green' : ''">-->
                <!--                        <div class="row__text">-->
                <!--                          <a :href="doc.file" v-if="doc.isPublic" target="_blank">-->
                <!--                            {{ documentTypesEnum[doc.documentType] }}-->
                <!--                          </a>-->
                <!--                          <p class="privateDoc" v-else @click="getPrivateDoc(doc.id)">-->
                <!--                            {{ documentTypesEnum[doc.documentType] }}-->
                <!--                          </p>-->
                <!--                          <p class="row__update" v-if="doc.updateStatus === 0 || doc.updateStatus === 3">-->
                <!--                            Обновлено-->
                <!--                          </p>-->
                <!--                          <div class="edit-doctype-block" v-if="doc.updateStatus !== 0 && doc.updateStatus !== 3">-->
                <!--                            <img src="@/assets/images/icons/pencil.svg" alt="pencil" class="js-tooltip-icon"-->
                <!--                                 :data-id="'tooltip-doctype-' + doc.documentType">-->
                <!--                            <div class="editing" :style="tooltipElemCoord" :id="'tooltip-doctype-' + doc.documentType">-->
                <!--                              <Button @click="openPopupDoc(doc)">Заменить</Button>-->
                <!--                              <Button :class="'btn&#45;&#45;grey'" @click="openPopupDelDoc(doc)">Удалить</Button>-->
                <!--                            </div>-->
                <!--                          </div>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </template>-->
                <!-- ^ ДОКУМЕНТ НА ОПЛАТУ ^ -->


                <!--                <div class="row">-->
                <!--                  <div class="add-doc-6" v-if="noDocTypeSix">-->
                <!--                    <button class="btn" @click="openPopupDoc({documentType: 6, file: ''})">-->
                <!--                      <div class="text-wrap">-->
                <!--                        <img src="@/assets/images/icons/document.svg" alt="document">-->
                <!--                        Загрузите подтверждающий документ-->
                <!--                      </div>-->
                <!--                      <img src="@/assets/images/icons/plus&#45;&#45;white.svg" alt="add">-->
                <!--                    </button>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="su-body__block-descr">
                  <p>
                    Дата регистрации:
                  </p>
                  <p>
                    {{
                      getDateTime(user.createdAt)
                    }}
                  </p>
                </div>

                <Row>
                  <template v-slot:label>
                    <p class="row__label">
                      Почта регистрации
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ user.email }}
                    </p>
                  </template>
                </Row>

                <div class="su-body__block-descr">
                  <p>
                    Дата подтверждения почты {{ user.isVerifiedAt ? ":" : "отсутствует" }}
                  </p>
                  <p>
                    {{
                      getDateTime(+user.isVerifiedAt)
                    }}
                  </p>
                </div>


                <div class="su-body__block-title">
                  <p>
                    Информация пользователя
                  </p>
                </div>

                <!-- ОРГАНИЗАЦИОННО-ПРАВОВАЯ ФОРМА -->
                <Row v-if="user.organizationType !== null">
                  <template v-slot:label>
                    <p class="row__label">
                      Организационно-провавая форма
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getOrgTypeName(user.organizationType) }}
                    </p>
                  </template>
                </Row>

                <Row v-if="getDataToUpdate.organizationType !== undefined" :isUpdate="getDataToUpdate.organizationType">
                  <template v-slot:label>
                    <p class="row__label">
                      Организационно-провавая форма
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.organizationType !== null
                            ? getOrgTypeName(getDataToUpdate.organizationType)
                            : getOrgTypeName(user.organizationType)
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.organizationType ">
                    <p class="row__text">
                      {{ getOrgTypeName(user.organizationType) }}
                    </p>
                  </template>
                </Row>

                <!-- НАИМЕНОВАНИЕ ИЛИ ФИО -->
                <Row v-if="user.name" :isUpdate="getDataToUpdate.name">
                  <template v-slot:label>
                    <p class="row__label">
                      {{
                        (user.organizationType !== null && getDataToUpdate.organizationType === organizationTypesEnum['Физлицо']) ||
                        user.organizationType === organizationTypesEnum['Физлицо']
                            ? 'ФИО'
                            : 'Наименование'
                      }}
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.name || user.name
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.name">
                    <p class="row__text">
                      {{ user.name }}
                    </p>
                  </template>
                </Row>


                <!-- ФИЗЛИЦО -->
                <template
                    v-if="
                          getDataToUpdate.organizationType === organizationTypesEnum['Физлицо'] ||
                          user.organizationType === organizationTypesEnum['Физлицо']
                    ">
                  <!-- ПАСПОРТ СЕРИЯ-->
                  <Row v-if="user.passportSeries" :isUpdate="getDataToUpdate.passportSeries">
                    <template v-slot:label>
                      <p class="row__label">
                        Серия паспорта
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text">
                        {{
                          getDataToUpdate.passportSeries
                              ? getDataToUpdate.passportSeries
                              : user.passportSeries
                        }}
                      </p>
                    </template>
                    <template v-slot:old-text v-if="getDataToUpdate.passportSeries">
                      <p class="row__text">
                        {{ user.passportSeries }}
                      </p>
                    </template>
                  </Row>
                  <!-- ПАСПОРТ НОМЕР-->
                  <Row v-if="user.passportNumber" :isUpdate="getDataToUpdate.passportNumber">
                    <template v-slot:label>
                      <p class="row__label">
                        Номер паспорта
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text">
                        {{
                          getDataToUpdate.passportNumber
                              ? getDataToUpdate.passportNumber
                              : user.passportNumber
                        }}
                      </p>
                    </template>
                    <template v-slot:old-text v-if="getDataToUpdate.passportNumber">
                      <p class="row__text">
                        {{ user.passportNumber }}
                      </p>
                    </template>
                  </Row>

                  <Row v-if="user.birthDate" :isUpdate="getDataToUpdate.birthDate">
                    <template v-slot:label>
                      <p class="row__label">
                        Дата рождения
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text">
                        {{
                          getDataToUpdate.birthDate ?
                              getDate(getDataToUpdate.birthDate) :
                              getDate(user.birthDate)
                        }}
                      </p>
                    </template>
                    <template v-slot:old-text v-if="getDataToUpdate.birthDate">
                      <p class="row__text">
                        {{ getDate(user.birthDate) }}
                      </p>
                    </template>
                  </Row>

                  <Row :isUpdate="getDataToUpdate.isSelfEmployed!==undefined">
                    <template v-slot:label>
                      <p class="row__label">
                        Является плательщиком на проф. доход
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text">
                        {{
                          (getDataToUpdate.isSelfEmployed !== undefined
                              ? getDataToUpdate.isSelfEmployed
                              : user.isSelfEmployed) ? 'да' : 'нет'
                        }}
                      </p>
                    </template>
                    <template v-slot:old-text v-if="getDataToUpdate.isSelfEmployed !==undefined">
                      <p class="row__text">
                        {{ user.isSelfEmployed ? 'да' : 'нет' }}
                      </p>
                    </template>
                  </Row>
                </template>

                <!-- Если не физлицо, то выводим также ФИО руководителя -->
                <template v-else>
                  <!-- ФИО -->
                  <Row :isUpdate="isFioUpdated">
                    <template v-slot:label>
                      <p class="row__label">
                        ФИО Руководителя
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text">
                        {{ isFioUpdated ? getFioUpdated : getFio }}
                      </p>
                    </template>
                    <template v-slot:old-text v-if="isFioUpdated">
                      <p class="row__text">
                        {{ getFio }}
                      </p>
                    </template>
                  </Row>
                  <!--                  <Row v-if="user.lastName" :isUpdate="getDataToUpdate.lastName">-->
                  <!--                    <template v-slot:label>-->
                  <!--                      <p class="row__label">-->
                  <!--                        Фамилия руководителя-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:current-text>-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{-->
                  <!--                          getDataToUpdate.lastName-->
                  <!--                              ? getDataToUpdate.lastName-->
                  <!--                              : user.lastName-->
                  <!--                        }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:old-text v-if="getDataToUpdate.lastName">-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{ user.lastName }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                  </Row>-->
                  <!--                  <Row v-if="user.firstName" :isUpdate="getDataToUpdate.firstName">-->
                  <!--                    <template v-slot:label>-->
                  <!--                      <p class="row__label">-->
                  <!--                        Имя руководителя-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:current-text>-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{-->
                  <!--                          getDataToUpdate.firstName-->
                  <!--                              ? getDataToUpdate.firstName-->
                  <!--                              : user.firstName-->
                  <!--                        }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:old-text v-if="getDataToUpdate.firstName">-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{ user.firstName }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                  </Row>-->
                  <!--                  <Row v-if="user.middleName" :isUpdate="getDataToUpdate.middleName">-->
                  <!--                    <template v-slot:label>-->
                  <!--                      <p class="row__label">-->
                  <!--                        Отчество руководителя-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:current-text>-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{-->
                  <!--                          getDataToUpdate.middleName-->
                  <!--                              ? getDataToUpdate.middleName-->
                  <!--                              : user.middleName-->
                  <!--                        }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                    <template v-slot:old-text v-if="getDataToUpdate.middleName">-->
                  <!--                      <p class="row__text">-->
                  <!--                        {{ user.middleName }}-->
                  <!--                      </p>-->
                  <!--                    </template>-->
                  <!--                  </Row>-->
                </template>

                <!-- ОГРН -->
                <Row v-if="user.ogrn" :isUpdate="getDataToUpdate.ogrn">
                  <template v-slot:label>
                    <p class="row__label">
                      ОГРН
                      <template v-if="user.organizationType === organizationTypesEnum['ИП']">ИП</template>
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getDataToUpdate.ogrn ? getDataToUpdate.ogrn : user.ogrn }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.ogrn">
                    <p class="row__text">
                      {{ user.ogrn }}
                    </p>
                  </template>
                </Row>

                <!-- ИНН -->
                <Row v-if="user.inn" :isUpdate="getDataToUpdate.inn">
                  <template v-slot:label>
                    <p class="row__label">
                      ИНН
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.inn
                            ? getDataToUpdate.inn
                            : user.inn
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.inn">
                    <p class="row__text">
                      {{ user.inn }}
                    </p>
                  </template>
                </Row>


                <Row v-if="user.taxationSystem" :isUpdate="getDataToUpdate.taxationSystem!==undefined">
                  <template v-slot:label>
                    <p class="row__label">
                      Система налогообложения
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.taxationSystem !== undefined
                            ? getDataToUpdate.taxationSystem === 0 ? 'ОСН' : 'УСН'
                            : user.taxationSystem === 0 ? 'ОСН' : 'УСН'
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.taxationSystem!==undefined">
                    <p class="row__text">
                      {{ user.taxationSystem === 0 ? 'ОСН' : 'УСН' }}
                    </p>
                  </template>
                </Row>
                <Row v-if="user.description" :class="'textarea'" :isUpdate="getDataToUpdate.description">
                  <template v-slot:label>
                    <p class="row__label">
                      Описание пользователя
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.description
                            ? getDataToUpdate.description
                            : user.description
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.description">
                    <p class="row__text">
                      {{ user.description }}
                    </p>
                  </template>
                </Row>
                <Row v-if="user.publicEmail" :isUpdate="getDataToUpdate.publicEmail">
                  <template v-slot:label>
                    <p class="row__label">
                      Email (публичный)
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.publicEmail
                            ? getDataToUpdate.publicEmail
                            : user.publicEmail
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.publicEmail">
                    <p class="row__text">
                      {{ user.publicEmail }}
                    </p>
                  </template>
                </Row>

                <!-- МЕДИА ФАЙЛЫ -->
                <!--                <div class="su-body__title" v-if="mediaList && mediaList.length">-->
                <!--                  <p>-->
                <!--                    Медиа-->
                <!--                  </p>-->
                <!--                </div>-->
                <!--                <div class="su-body__block-wrap" v-if="mediaList && mediaList.length">-->
                <!--                  <div class="su-body__block">-->
                <!--                    <div class="su-body__block-title">-->
                <!--                      <p>-->
                <!--                        Медиа-->
                <!--                      </p>-->
                <!--                    </div>-->
                <!--                    <div class="su-body__media">-->
                <!--                      <div class="su-body__media-image js-gallery-main-slider" @click="openPopup">-->
                <!--                        <swiper ref="headerSlider" class="header-slider" :options="headerSwiper">-->
                <!--                          <swiper-slide class="lot-single" v-for="(item, idx) in mediaList" :key="idx">-->
                <!--                            <span class="ls-body__media-label">-->
                <!--                              {{ isImage(item.mimeType) ? 'Фото' : 'Видео' }}-->
                <!--                            </span>-->
                <!--                            <span class="ls-body__media-update"-->
                <!--                                  v-if="item.updateStatus === updateStatusesEnum.UPDATE_INTENDED">-->
                <!--                              Обновлено-->
                <!--                            </span>-->
                <!--                            <span v-if="isVideo(item.mimeType)" class="ls-body__media-play"></span>-->
                <!--                            <img :src="item.file" alt="gallery"-->
                <!--                                 :class="{ update: item.updateStatus === updateStatusesEnum.UPDATE_INTENDED }"-->
                <!--                                 v-if="isImage(item.mimeType)">-->
                <!--                            <img :src="item.base64Preview" alt="gallery" :data-video-src="item.file"-->
                <!--                                 :class="{ update: item.updateStatus === updateStatusesEnum.UPDATE_INTENDED }"-->
                <!--                                 v-else-if="isVideo(item.mimeType)">-->
                <!--                          </swiper-slide>-->
                <!--                        </swiper>-->
                <!--                      </div>-->
                <!--                      <div class="swiper-navigation-wrap">-->
                <!--                        <div class="swiper-button swiper-button-prev">-->
                <!--                          <img src="@/assets/images/icons/arrow-left.svg" alt="arrow">-->
                <!--                        </div>-->
                <!--                        <div class="swiper-button swiper-button-next">-->
                <!--                          <img src="@/assets/images/icons/arrow-right.svg" alt="arrow">-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <div class="swiper-pagination-wrapper">-->
                <!--                        <div class="swiper-pagination" slot="pagination"></div>-->
                <!--                      </div>-->
                <!--                      <swiper ref="footerSlider" class="footer-slider" :options="footerSwiper">-->
                <!--                        <swiper-slide-->
                <!--                            class="su-body__media-thumbnail js-media-thumbnail"-->
                <!--                            v-for="(item, idx) in mediaList"-->
                <!--                            :key="idx"-->
                <!--                        >-->
                <!--                          <img :src="item.file" alt="gallery"-->
                <!--                               :class="{ update: item.updateStatus === updateStatusesEnum.UPDATE_INTENDED }"-->
                <!--                               v-if="isImage(item.mimeType)"-->
                <!--                               @click="changeTopSlider(idx)">-->
                <!--                          <img-->
                <!--                              :src="item.base64Preview"-->
                <!--                              alt="gallery"-->
                <!--                              :data-video-src="item.file"-->
                <!--                              :class="{ update: item.updateStatus === updateStatusesEnum.UPDATE_INTENDED }"-->
                <!--                              v-else-if="isVideo(item.mimeType)"-->
                <!--                              @click="changeTopSlider(idx)"-->
                <!--                          >-->
                <!--                        </swiper-slide>-->
                <!--                      </swiper>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>
            </div>
          </div>

          <!-- ПРАВАЯ КОЛОНКА -->
          <div class="su-rightside">
            <div class="su-body__title">
              <p>
                Реквизиты
              </p>
            </div>
            <div class="su-body__block-wrap">
              <div class="su-body__block">
                <div class="su-body__block-title">
                  <p>
                    Адрес
                  </p>
                </div>

                <Row v-if="user.legalAddress" :isUpdate="getDataToUpdate.legalAddress">
                  <template v-slot:label>
                    <p class="row__label">
                      Юридический адрес
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.legalAddress
                            ? getDataToUpdate.legalAddress
                            : user.legalAddress
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.legalAddress">
                    <p class="row__text">
                      {{ user.legalAddress }}
                    </p>
                  </template>
                </Row>
                <Row v-if="user.website" :isUpdate="getDataToUpdate.website">
                  <template v-slot:label>
                    <p class="row__label">
                      Сайт
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getDataToUpdate.website ? getDataToUpdate.website : user.website }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.website">
                    <p class="row__text">
                      {{ user.website }}
                    </p>
                  </template>
                </Row>
                <Row v-if="user.fio" :isUpdate="getDataToUpdate.fio">
                  <template v-slot:label>
                    <p class="row__label">
                      ФИО ГД
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getDataToUpdate.fio ? getDataToUpdate.fio : user.fio }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.fio">
                    <p class="row__text">
                      {{ user.fio }}
                    </p>
                  </template>
                </Row>
                <Row v-if="user.publicPhone" :isUpdate="getDataToUpdate.publicPhone">
                  <template v-slot:label>
                    <p class="row__label">
                      Контактный телефон
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getDataToUpdate.publicPhone ? getDataToUpdate.publicPhone : user.publicPhone }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.publicPhone">
                    <p class="row__text">
                      {{ user.publicPhone }}
                    </p>
                  </template>
                </Row>

                <div class="su-body__block-title">
                  <p>
                    Банковские реквизиты
                  </p>
                </div>
                <Row v-if="user.kpp" :isUpdate="getDataToUpdate.kpp">
                  <template v-slot:label>
                    <p class="row__label">
                      КПП клиента
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{
                        getDataToUpdate.kpp
                            ? getDataToUpdate.kpp
                            : user.kpp
                      }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getDataToUpdate.kpp">
                    <p class="row__text">
                      {{ user.kpp }}
                    </p>
                  </template>
                </Row>

              </div>
            </div>

            <!-- ^ ДОКУМЕНТЫ ДОЛЖНОСТНОЙ ОСМОТРИТЕЛЬНОСТИ ^ -->
            <div class="su-body__title">
              <p>
                Документы должностной осмотрительности
              </p>
            </div>
            <div class="su-body__block-wrap">
              <div class="su-body__block">
                <template v-for="(doc, idx) in documentsList">
                  <template v-if="doc.documentType">
                    <Row
                        :class="[updateStatusesEnum.INITIAL,updateStatusesEnum.UPDATE_INTENDED].includes(doc.updateStatus)
                            ? 'ai-center jc-between green'
                            : 'ai-center jc-between'"
                        :key="idx"
                    >
                      <template v-slot:current-text>
                        <div class="row__text"> <!--  v-if="doc.file" -->
                          <a
                              :href="doc.file"
                              v-if="doc.isPublic"
                              target="_blank"
                          >
                            {{ documentTypesEnum[doc.documentType] }}
                          </a>
                          <div class="privateDoc" v-else
                               @click="getPrivateDoc(user.id,doc.id)">
                            {{ documentTypesEnum[doc.documentType] }}
                          </div>
                          <span
                              class="row__update"
                              v-if="[updateStatusesEnum.INITIAL,updateStatusesEnum.UPDATE_INTENDED].includes(doc.updateStatus)"
                          >
                            Обновлено
                          </span>

                          <!--    Редактирование файла      -->
                          <!--                          <div class="edit-doctype-block"-->
                          <!--                               v-if="[updateStatusesEnum.APPROVED, updateStatusesEnum.DECLINED].includes(doc.updateStatus)">-->
                          <!--                            <img src="@/assets/images/icons/pencil.svg" alt="pencil" class="js-tooltip-icon"-->
                          <!--                                 style="cursor: pointer"-->
                          <!--                                 :data-id="'tooltip-doctype-' + doc.id">-->
                          <!--                            <div class="editing" :style="tooltipElemCoord" :id="'tooltip-doctype-' + doc.id">-->
                          <!--                              <Button @click="openPopupDoc(doc)">Заменить</Button>-->
                          <!--                              <Button :class="'btn&#45;&#45;grey'" @click="openPopupDelDoc(doc)">Удалить</Button>-->
                          <!--                            </div>-->
                          <!--                          </div>-->
                          <!--    Редактирование файла      -->
                        </div>

                        <!--                        <div class="row__text" v-else>-->
                        <!--                          <p>-->
                        <!--                            {{ documentTypesEnum[doc.documentType] }}-->
                        <!--                          </p>-->
                        <!--                        </div>-->
                      </template>
                    </Row>
                  </template>

                </template>
                <!--                <div class="row">-->
                <!--                  <Button @click="openPopupDoc">-->
                <!--                    Загрузить документ-->
                <!--                  </Button>-->
                <!--                </div>-->
              </div>
            </div>

            <!-- ^ ДОКУМЕНТЫ ДОЛЖНОСТНОЙ ОСМОТРИТЕЛЬНОСТИ ^ -->
          </div>
        </div>

        <!-- v КНОПКИ УПРАВЛЕНИЯ v -->
        <template v-if="(user.isActive === false)">
          <div class="su-footer">
            <div class="su-footer__controlls">
              <Button @click="toUnblockUser(user.id)">Разблокировать</Button>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="su-footer" v-if="user.deletionStatus === userDeletionStatusesEnum.DELETED"></div>
          <template v-else>
            <div class="su-footer" v-if="user.status === userStatusesEnum.INITIAL">
              <div class="su-footer__block js-reason-block">
                <Row :class="'textarea'">
                  <template v-slot:label>
                    <p class="row__label">
                      Причина ограничения функционала профиля
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text js-reason-parag" contenteditable></p>
                  </template>
                </Row>
                <span class="error-text">Укажите причину ограничения</span>
              </div>
              <div class="su-footer__controlls mb-1rem" :class="{'form-send': isSend}">
                <Button @click="toActivateUser(user.id)" :class="'btn--green'">Активировать</Button>
                <Button @click="toDeactivateUser(user.id)" :class="'btn--black'">Ограничить функционал
                </Button>
              </div>
              <div class="su-footer__controlls" :class="{'form-send': isSend}">
                <Button @click="toBlockUser(user.id)" :class="'btn--black'">Заблокировать</Button>
              </div>
            </div>

            <template v-if="user.status === userStatusesEnum.APPROVED">
              <div class="su-footer" v-if="user.updateStatus !== updateStatusesEnum.UPDATE_INTENDED">
                <div class="su-footer__block js-reason-block">
                  <Row :class="'textarea'">
                    <template v-slot:label>
                      <p class="row__label">
                        Причина ограничения функционала профиля
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text js-reason-parag" contenteditable></p>
                    </template>
                  </Row>
                  <span class="error-text">Укажите причину оганичения</span>
                </div>
                <div class="su-footer__controlls" :class="{'form-send': isSend}">
                  <Button @click="toDeactivateUser(user.id)" :class="'btn--black'">Ограничить функционал
                  </Button>
                  <Button @click="toBlockUser(user.id)" :class="'btn--black'">Заблокировать</Button>
                </div>
              </div>
              <div class="su-footer" v-else>
                <div class="su-body__block-wrap mb-1rem" v-if="user.updateStatus === updateStatusesEnum.DECLINED">
                  <div class="su-body__block">
                    <Row :class="'textarea'">
                      <template v-slot:label>
                        <p class="row__label">
                          Причина отклонения изменений данных в профиле
                        </p>
                      </template>
                      <template v-slot:current-text>
                        <p class="row__text" v-html="user.moderationMessage"></p>
                      </template>
                    </Row>
                  </div>
                </div>
                <div class="su-footer__block js-reason-block"
                     v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED">
                  <Row :class="'textarea'">
                    <template v-slot:label>
                      <p class="row__label">
                        Причина ограничения функционала или отклонения изменений данных в профиле
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text js-reason-parag" contenteditable></p>
                    </template>
                  </Row>
                  <span class="error-text">Укажите причину ограничения или отклонения изменений</span>
                </div>
                <div class="su-footer__block js-reason-block" v-else>
                  <Row :class="'textarea'">
                    <template v-slot:label>
                      <p class="row__label">
                        Причина ограничения функционала профиля
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text js-reason-parag" contenteditable></p>
                    </template>
                  </Row>
                  <span class="error-text">Укажите причину ограничения</span>
                </div>
                <div class="su-footer__controlls mb-1rem" :class="{'form-send': isSend}"
                     v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED">
                  <Button @click="toPublishUser(user.id)">Принять изменения</Button>
                  <Button @click="toCancelUser(user.id)" :class="'btn--red'">Отклонить изменения</Button>
                </div>
                <div class="su-footer__controlls" :class="{'form-send': isSend}">
                  <Button @click="toDeactivateUser(user.id)" :class="'btn--black'">Ограничить функционал
                  </Button>
                  <Button @click="toBlockUser(user.id)" :class="'btn--black'">Заблокировать</Button>
                </div>
              </div>
            </template>

            <div class="su-footer" v-else-if="user.status === userStatusesEnum.DECLINED">
              <div class="su-body__block-wrap mb-1rem" v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED">
                <div class="su-body__block">
                  <Row :class="'textarea'">
                    <template v-slot:label>
                      <p class="row__label">
                        Причина ограничения функционала профиля
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text" v-html="user.moderationMessage"></p>
                    </template>
                  </Row>
                </div>
              </div>
              <div class="su-footer__block js-reason-block"
                   v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED">
                <Row :class="'textarea'">
                  <template v-slot:label>
                    <p class="row__label">
                      Причина отклонения изменений
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text js-reason-parag" contenteditable></p>
                  </template>
                </Row>
                <span class="error-text">Укажите причину отклонения</span>
              </div>
              <div class="su-body__block-wrap" v-else>
                <div class="su-body__block">
                  <Row :class="'textarea'">
                    <template v-slot:label>
                      <p class="row__label">
                        Причина ограничения функционала или отклонения изменений данных в профиле
                      </p>
                    </template>
                    <template v-slot:current-text>
                      <p class="row__text" v-html="user.moderationMessage"></p>
                    </template>
                  </Row>
                </div>
              </div>
              <div class="su-footer__controlls mb-1rem" v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED"
                   :class="{'form-send': isSend}">
                <Button @click="toPublishUser(user.id)">Принять изменения (без снятия ограничения)</Button>
                <Button @click="toCancelUser(user.id)" :class="'btn--red'">Отклонить изменения</Button>
              </div>
              <div class="su-footer__controlls" v-if="user.updateStatus === updateStatusesEnum.UPDATE_INTENDED"
                   :class="{'form-send': isSend}">
                <Button @click="toActivateUser(user.id)" :class="'btn--green'">Принять изменения / Снять
                  ограничения
                </Button>
              </div>
              <div class="su-footer__controlls" v-else :class="{'form-send': isSend}">
                <Button @click="toActivateUser(user.id)" :class="'btn--green'">Принять изменения / Снять
                  ограничения
                </Button>
              </div>
            </div>
          </template>
        </template>
        <!-- ^ КНОПКИ УПРАВЛЕНИЯ ^ -->

        <!-- ПОПАПЫ -->
        <transition-group name="fadeIn">
          <div class="popup" v-show="showPopup" @click="closePopup" key="media">
            <div class="popup__window popup__window--media" @click.stop="">
              <span class="popup__close" @click="closePopup">
                <img src="@/assets/images/icons/close.svg" alt="close">
              </span>
              <template v-if="videoLink.length">
                <div class="popup__inner js-media-inner" v-for="(video, idx) in videoLink" :key="idx">
                  <video controls class="popup__media">
                    <source :src="videoLink" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </template>
              <template v-if="imageLink.length">
                <div class="popup__inner js-media-inner" v-for="(image, idx) in imageLink" :key="idx">
                  <img :src="image" alt="" class="popup__media">
                </div>
              </template>
            </div>
          </div>

          <div class="popup" v-show="showPopupDoc" @click="closePopup" key="editing">
            <div class="popup__window" @click.stop="">
              <span class="popup__close" @click="closePopup">
                <img src="@/assets/images/icons/close.svg" alt="close">
              </span>
              <div class="popup__inner js-media-inner">
                <div class="popup__title">
                  <p v-if="disabledDocType">
                    Заменить документ
                  </p>
                  <p v-else>
                    Добавление документа
                  </p>
                </div>
                <form class="form-edit-admin" @submit.prevent="sendDoc">
                  <div class="form__item">
                    <input
                        readonly
                        :disabled="disabledDocType"
                        class="form__input form__input--arrow"
                        id="docTypeName"
                        v-model.lazy="docTypeName"
                        type="text"
                        placeholder="Тип документа"
                        @focus="showListType = true"
                        @blur="showListType = false"
                        name=""
                    >
                    <label for="docTypeName" class="form__label">
                      <span>Тип документа</span>
                      <img src="@/assets/images/icons/arrow-down.svg" alt="down">
                    </label>
                    <transition name="fade">
                      <div class="select-list" v-show="showListType">
                        <ul>
                          <li v-for="(type, idx) in documentTypesEnum" :key="idx">
                            <div class="input-controlls">
                              <input class="select__input js-select-input" :value="idx" name="type" type="radio"
                                     :id="'docType-' + idx">
                              <label :for="'docType-' + idx" @click="getDocType(idx)">
                                {{ type }}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                  <div class="form__item" :class="{ 'form__item--error': $v.fileName.$error}">
                    <label for="file" class="with-file">
                      <img src="@/assets/images/icons/pencil.svg" alt="pencil" style="cursor: pointer"
                           v-show="fileName">
                    </label>
                    <input
                        class="form__input form__input--filename"
                        id="fileName"
                        :class="{error: $v.fileName.$error}"
                        type="text"
                        @change="$v.fileName.$touch()"
                        v-show="fileName"
                        v-model="fileName" name=""
                    >
                    <input

                        class="form__input form__input--file"
                        id="file"
                        type="file"
                        placeholder="Тип"
                        @change="getFilename"
                        readonly
                        accept=".pdf"
                        name=""
                    >
                    <label for="file" class="btn" v-show="!fileName">Добавить документ</label>
                    <p class="form__item-error" v-if="!$v.fileName.required">
                      Обязательное поле
                    </p>
                  </div>
                  <div class="form__controlls form__controlls--decor" :class="{'form-send': isSend}">
                    <!-- <Button :disabled="!docTypeName || !fileName" :class="{'btn--disabled': !docTypeName || !fileName}"> -->
                    <Button :disabled="!fileName" :class="{'btn--disabled': !fileName}">
                      Подтвердить
                    </Button>
                    <Button type="button" class="btn--grey" @click="closePopup">
                      Отмена
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="popup" v-if="showPopupDelDoc" @click="closePopup" key="del">
            <div class="popup__window" @click.stop="">
              <!-- <span class="popup__close" @click="closePopup">
                <img src="@/assets/images/icons/close.svg" alt="close">
              </span> -->
              <div class="popup__inner">
                <div class="popup__title">
                  Вы уверены что хотите удалить документ?
                </div>
                <div class="popup__controlls">
                  <Button :class="'btn--red'" @click="deleteDoc">Удалить</Button>
                  <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
                </div>
              </div>
            </div>
          </div>

          <div class="popup" v-if="showPrivateDoc" @click="closePopup" key="private">
            <div class="popup__window" @click.stop="">
                        <span class="popup__close" @click="closePopup">
                          <img src="@/assets/images/icons/close.svg" alt="close">
                        </span>
              <div class="popup__inner">
                {{ privateDoc }}
              </div>
            </div>
          </div>


        </transition-group>
      </div>

      <!-- ЗАГЛУШКА -->
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {required} from 'vuelidate/lib/validators'
import * as moment from 'moment'
import Loader from '@/components/Loader.vue'
import Row from '@/components/UI/SingleDescrRow.vue'
import Button from '@/components/UI/Button.vue'
import EmptyBlock from '@/components/Empty.vue'
import Input from "@/components/UI/Input.vue";
import {
  documentTypesEnum,
  organizationTypesEnum,
  updateStatusesEnum,
  userDeletionStatusesEnum,
  userStatusesEnum
} from "@/enums";

export default {
  name: 'UserAboutView',
  components: {
    Input,
    Loader,
    EmptyBlock,
    Row,
    Button,
  },
  data() {
    return {
      loading: true,
      isSend: false,
      showPopup: false,
      showPopupDoc: false,
      showPopupDelDoc: false,
      showPrivateDoc: false,
      privateDoc: null,
      videoLink: [],
      imageLink: [],
      isImageUpdate: false,
      showListType: false,
      disabledDocType: false,
      docTypeName: null,
      docTypeId: '',
      docId: null,
      file: null,
      noDocTypeSix: true,
      fileName: '',
      datePayment: null,
      // subscriptionsDisabled: false,
      // subscriptionNextPaymentDt: null,
      // subscriptionExpirationDt: null,
      headerSwiper: {
        pagination: {
          el: '.swiper-pagination',
        },
        slidesPerView: 1,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      footerSwiper: {
        slidesPerView: 5,
      },
      tooltipElemCoord: {
        top: '5rem',
        left: '',
        right: ''
      },
    }
  },
  validations: {
    fileName: {
      required,
    },
    datePayment: {
      required,
      minValue(val) {
        let dateArray = val.split('.')
        let date = new Date(`${dateArray[1]}.${dateArray[0] - 1}.${dateArray[2]}`)
        return date.getTime() < Date.now();
      },
    },
  },
  created() {
    let userId = this.$route.params.id
    let localUserId = localStorage.getItem('userId') || null
    if (userId !== localUserId) {
      this.$store.state.singleUser.user = null
      this.$store.state.employee.userEmployees = null

      this.loading = true
      this.$store.dispatch('pageTitle', ' ')
    }
    this.$store.dispatch('fetchUser', userId)
    this.$store.dispatch('fetchUserDocuments', userId)
    // this.$store.dispatch('fetchUserMedia', userId)
  },
  computed: {
    userDeletionStatusesEnum() {
      return userDeletionStatusesEnum
    },
    updateStatusesEnum() {
      return updateStatusesEnum
    },
    userStatusesEnum() {
      return userStatusesEnum
    },
    documentTypesEnum() {
      return documentTypesEnum
    },
    organizationTypesEnum() {
      return organizationTypesEnum
    },
    isLoading() {
      this.user
    },
    // subscriptionsDisabled() {
    //   return this.getSubscriptionsDisabled
    // },
    subscriptionPaidAt() {
      const _subs = this.user.subscriptions || []
      if (_subs.length) {
        return _subs[0].paidAt
      }
      return null
    },
    subscriptionNextPaymentDt() {
      const _subs = this.user.subscriptions || []
      if (_subs.length) {
        return _subs[0].nextPaymentDt
      }
      return null
    },
    documentsList() {
      return this.$store.getters.getDocuments
    },
    mediaList() {
      return this.$store.getters.getMedia
    },
    user() {
      let data = this.$store.getters.getUser
      let title = data?.name
      if (data) {
        this.loading = false

        if (data.dataToUpdate?.name) {
          title = `${data.name} - ${data.dataToUpdate.name}`
        }
      }

      // Устанавливаем заголовок страницы
      this.$store.dispatch('pageTitle', title)

      return data
    },
    getDataToUpdate() {
      // if (this.getUser && this.user.dataToUpdate) {
      //   return this.user.dataToUpdate
      // } else {
      return this.user.dataToUpdate || {}
      // }
    },
    getFio() {
      let _parts = [];
      _parts.push(this.user.lastName)
      _parts.push(this.user.firstName)
      _parts.push(this.user.middleName)
      return _parts.join(' ')
    },
    getFioUpdated() {
      let _parts = [];
      if (this.user.dataToUpdate.lastName === undefined) { // Не изменён
        _parts.push(this.user.lastName)
      } else if (this.user.dataToUpdate.lastName) {
        _parts.push(this.user.dataToUpdate.lastName)
      }

      if (this.user.dataToUpdate.firstName === undefined) { // Не изменён
        _parts.push(this.user.firstName)
      } else if (this.user.dataToUpdate.firstName) {
        _parts.push(this.user.dataToUpdate.firstName)
      }
      if (this.user.dataToUpdate.middleName === undefined) { // Не изменён
        _parts.push(this.user.middleName)
      } else if (this.user.dataToUpdate.middleName) {
        _parts.push(this.user.dataToUpdate.middleName)
      }
      return _parts.join(' ')
    },
    isFioUpdated() {
      return this.user.dataToUpdate?.lastName !== undefined || this.user.dataToUpdate?.firstName !== undefined || this.user.dataToUpdate?.middleName !== undefined
    }
  },
  methods: {
    getFullName(user) {
      const firstName = user.firstName
      const middleName = user.middleName
      const lastName = user.lastName

      if (this.getDataToUpdate.firstName || this.getDataToUpdate.middleName || this.getDataToUpdate.lastName) {
        this.fullName = {
          isUpdate: true,
          textUpdated: `${this.getDataToUpdate.lastName || lastName} ${this.getDataToUpdate.firstName || firstName} ${this.getDataToUpdate.middleName || middleName}`,
          textCurrent: `${lastName} ${firstName} ${middleName}`
        }
      } else {
        this.fullName = {
          isUpdate: false,
          textUpdated: '',
          textCurrent: `${lastName} ${firstName} ${middleName}`
        }
      }
    },
    isImage(mimeType) {
      return ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'image/svg+xml'].includes(mimeType)
    },
    isVideo(mimeType) {
      return [
        'video/mp4', 'video/avi', 'video/x-matroska', 'video/quicktime', 'video/x-ms-wmv', 'video/mpeg', 'video/mpeg', 'video/3gpp', 'video/webm'
      ].includes(mimeType)
    },
    getOrgTypeName(orgType) {
      return Object.keys(organizationTypesEnum).find(key => organizationTypesEnum[key] === orgType) || null;
    },
    getUserAvatarStyle(user) {
      /* Получить стили для аватара
      * */
      const _backgroundColor = user.avatarBackground || "ffffff"

      // Переводим в целое число из hex коды цветов
      const r = parseInt(_backgroundColor.slice(0, 2), 16);
      const g = parseInt(_backgroundColor.slice(2, 4), 16);
      const b = parseInt(_backgroundColor.slice(4, 6), 16);

      // Инвертируем RGB hex
      const invertedR = (255 - r).toString(16).padStart(2, '0');
      const invertedG = (255 - g).toString(16).padStart(2, '0');
      const invertedB = (255 - b).toString(16).padStart(2, '0');

      // Составляем заново строку RGB hex
      const invertedHexColor = `#${invertedR}${invertedG}${invertedB}`;

      return {
        display: 'flex',
        color: invertedHexColor,
        fontSize: '6rem',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        background: `radial-gradient(circle at 99% 99%,#${_backgroundColor} 50%,#ffffff 120%)`,
      }
    },
    getInitials(user) {
      /* Получить инициалы
      * */
      if (!user || !user.name) {
        return '🛇'
      }
      const parts = user.name.split(/[\s"']+/);

      // Фильруем пустые строки и ненужные части
      const filteredParts = parts.filter(part => part.length > 0 && !["ИП", "ООО", "АО"].includes(part));

      // Первые 2 элемента
      const nameParts = filteredParts.slice(0, 2);

      // Первые буквы
      const initials = nameParts.map(part => part[0]).join('');

      return initials.toUpperCase();
    },
    getDate(timestamp) {
      if (isNaN(+timestamp)) {
        return null
      }
      if (+timestamp) {
        return moment(new Date(+timestamp).getTime()).format("DD.MM.YYYY")
      }
      return null
    },
    getDateTime(timestamp) {
      if (isNaN(+timestamp)) {
        return null
      }
      if (+timestamp) {
        return moment(new Date(+timestamp).getTime()).format("DD.MM.YYYY HH:mm")
      }
      return null
    },
    changeTopSlider(idx) {
      this.$refs.headerSlider.$swiper.slideTo(idx, 0)
    },
    openPopup(event) {
      this.showPopup = true
      let element = event.target
      let isUpdate = element.classList.contains('update') ? 'update' : ''

      if (element.dataset.videoSrc) {
        this.isImageUpdate = isUpdate.length
        this.videoLink.push(element.dataset.videoSrc)
      } else {
        this.isImageUpdate = isUpdate.length
        this.imageLink.push(element.src)
      }
    },
    closePopup(event) {
      this.showPopup = false
      this.showPopupDoc = false
      this.showPopupDelDoc = false
      this.showPrivateDoc = false

      this.$v.$reset()
      this.videoLink = []
      this.imageLink = []
    },
    openPopupDoc(elem) {
      this.showPopupDoc = true
      if (elem.documentType !== null) {
        this.disabledDocType = true
        this.fileName = elem.file
        this.docTypeName = documentTypesEnum[elem.documentType]
        this.docTypeId = elem.documentType
        return
      }

      this.disabledDocType = false
      this.fileName = ''
      this.docTypeName = ''
    },
    openPopupDelDoc(elem) {
      this.showPopupDelDoc = true
      this.docId = elem.id
    },
    async toPublishUser(id) {
      let userUpdateStatus = this.user.updateStatus
      let user = {
        id: id,
        moderationMessage: null
      }

      if (userUpdateStatus === updateStatusesEnum.UPDATE_INTENDED) {
        user.updateStatus = updateStatusesEnum.APPROVED
      } else {
        user.status = userStatusesEnum.APPROVED
      }

      this.isSend = true
      await this.$store.dispatch('moderateUserUpdates', user)
      this.isSend = false

    },
    async toCancelUser(id) {
      const reasonElem = document.querySelector(`.js-reason-parag`)
      const parentBlock = reasonElem.closest('.js-reason-block')
      let reasonTxt = reasonElem.textContent.trim().replaceAll('&nbsp;', '');

      if (!reasonTxt.length) {
        parentBlock.classList.add('show-error')
        return
      }

      let userUpdateStatus = this.user.updateStatus
      let user = {
        id: id,
        moderationMessage: reasonTxt
      }

      if (userUpdateStatus === updateStatusesEnum.UPDATE_INTENDED) {
        user.updateStatus = updateStatusesEnum.DECLINED
      } else {
        user.status = userStatusesEnum.DECLINED
      }

      parentBlock.classList.remove('show-error')
      this.isSend = true
      await this.$store.dispatch('moderateUserUpdates', user)
      this.isSend = false
      reasonElem.innerHTML = '';
    },
    async toActivateUser(id) {
      let user = {
        id: id,
        moderationMessage: null,
        status: userStatusesEnum.APPROVED
      }

      this.isSend = true
      await this.$store.dispatch('moderateUser', user)
      this.isSend = false

    },
    async toDeactivateUser(id) {
      const reasonElem = document.querySelector(`.js-reason-parag`)
      const parentBlock = reasonElem.closest('.js-reason-block')
      let reasonTxt = reasonElem.textContent.trim().replaceAll('&nbsp;', '');

      if (!reasonTxt.length) {
        parentBlock.classList.add('show-error')
        return
      }

      let user = {
        id: id,
        moderationMessage: reasonTxt,
        status: userStatusesEnum.DECLINED
      }

      parentBlock.classList.remove('show-error')
      this.isSend = true
      await this.$store.dispatch('moderateUser', user)
      this.isSend = false
      reasonElem.innerHTML = '';
    },
    toBlockUser(id) {
      this.$store.dispatch('blockUser', id)
    },
    toUnblockUser(id) {
      this.$store.dispatch('unblockUser', id)
    },
    reverseSubscriptionsSettings(userId) {
      this.$store.dispatch('userSubscriptionsReverse', userId)
    },
    reverseMarketplaceSettings(userId) {
      this.$store.dispatch('userMarketplaceReverse', userId)
    },
    getFilename() {
      let _file = event.target.files[0]
      this.file = _file
      this.fileName = _file.name
    },
    getDocType(idx) {
      this.docTypeName = documentTypesEnum[idx]
      this.docTypeId = idx
    },
    async sendDoc() {
      this.$v.fileName.$touch()
      if (!this.$v.fileName.$invalid) {
        let userDocInfo = {
          file: this.file,
          docType: this.docTypeId,
          userId: this.$route.params.id
        }

        this.isSend = true
        await this.$store.dispatch('changeUserDoc', userDocInfo)
        this.isSend = false
        this.showPopupDoc = false
        this.docTypeId = null
        this.docTypeName = null
        this.fileName = ''
        this.$v.fileName.$reset()
        let fileInput = document.querySelector('#file')
        fileInput.value = ''
      }
    },
    async deleteDoc() {
      let userDocInfo = {
        docId: this.docId,
        userId: this.$route.params.id
      }

      this.isSend = true
      await this.$store.dispatch('deleteUserDoc', userDocInfo)
      this.isSend = false
      this.showPopupDelDoc = false
      this.docId = null

    },
    addPaymentDate() {
      let date = this.datePayment
      // if (!date || date.length < 10) return
      this.$v.datePayment.$touch()
      if (!this.$v.datePayment.$invalid) {
        let paymentId = this.user.servicePayment.id
        let dateArray = date.split('.')
        let newDate = (new Date(dateArray[2], dateArray[1] - 1, dateArray[0])).getTime();

        this.isSend = true
        this.$store.dispatch('updatePaymentDate', {
          paymentId,
          newDate
        })
        this.isSend = false
      }
      //  else {
      // const input = document.querySelector('#date-payment')
      // input.focus()
      // }
    },
    async getPrivateDoc(userId, docId) {
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/users/${userId}/documents/${docId}`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        let url = response.data.url
        window.open(url, '_blank');
      })
    },
    async getPrivateTempDoc(docId) {
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/users/documents/${docId}/temp_file`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        let url = response.data.url
        window.open(url, '_blank');
      })
    },
    // async editMonetaAccount() {
    //   this.$v.monetaRu.$touch()
    //   if (!this.$v.monetaRu.$invalid) {
    //     let monetaruInfo = {
    //       user: this.$route.params.id,
    //       account: this.monetaRu.account,
    //       short_account: this.monetaRu.shortAccount,
    //       contractId: this.monetaRu.contractId,
    //       accountId: this.monetaRu.accountId,
    //       trackedBalance: this.monetaRu.trackedBalance
    //     }
    //
    //     this.isSend = true
    //     await this.$store.dispatch('editMonetaAccount', monetaruInfo)
    //     this.isSend = false
    //   }
    //
    // }
  }
}
</script>


<style lang="scss" scoped>
.wrapper {
  .popup__window--media {
    max-width: 120rem;
    height: 92rem;
  }

  .popup__inner {
    margin-top: 0;
  }

  .popup__title {
    margin-bottom: 1.6rem;
  }
}

.su-body__block-regdoc {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.5rem;
  padding: 1rem 2.4rem;
  font-size: 1.4rem;
  color: #fff;
  background-color: #008069;
  border-radius: 10rem;
  cursor: pointer;

  img {
    width: 3.8rem;
    height: 3.8rem;
    object-fit: cover;
  }

  span {
    display: inline-block;
    margin-left: 2rem;
    margin-right: auto;
  }
}

.privateDoc {
  cursor: pointer;
}

.add-doc-6 .btn {
  justify-content: space-between;
  padding: 0 2.3rem;

  .text-wrap {
    display: inline-flex;
    align-items: center;

    img {
      width: 3.8rem;
      height: 3.8rem;
      margin-right: 1.2rem;
    }
  }
}

.edit-doctype-block {
  width: 2.4rem;

  img {
    width: 100%;
  }
}

.editing {
  display: none;
  position: absolute;
  width: 95rem;
  max-width: 38rem;
  padding: 2.6rem;
  background-color: #ffffff;
  border-radius: 3rem;
  box-shadow: 0px -.2rem 2rem rgba(113, 112, 128, 0.1);
  z-index: 5;

  &.open-tooltip {
    display: block;
  }

  .btn + .btn {
    margin-top: 1.6rem;
  }
}

.form__item .with-file {
  position: absolute;
  top: 2rem;
  right: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.form__input--file {
  position: absolute;
  opacity: 0;
  left: -1000%;
  pointer-events: none;
}

.form__input--filename {
  padding-right: 6rem;
  color: #008069;
}

.form__controlls {
  flex-direction: column;
}

.form__controlls--decor {
  position: relative;
  margin-top: 5.2rem;

  &::before {
    content: '';
    position: absolute;
    left: -6.2rem;
    top: -2.6rem;
    display: block;
    height: .1rem;
    width: calc(100% + 12.4rem);
    background-color: #F7F7FA;
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home"
import Login from "@/views/Login"
import AddAdmin from "@/views/Admin"

import Users from "@/views/Users"
import UsersList from "@/views/UsersList"
import UsersForDelete from "@/views/UsersForDelete"

import UserSingle from "@/views/UserSingle"
import UserAbout from "@/views/User/About"

import UserEmployees from "@/views/User/Employees"
import UserEmployeesList from "@/views/User/Employees/List"
import UserEmployeesItem from "@/views/User/Employees/Item"
import UserEmployeesCounterparties from "@/views/User/Employees/Counterparties"
import UserEmployeesProxy from "@/views/User/Employees/Proxy"

import UserCounterparties from "@/views/User/Counterparties"

import UserChats from "@/views/User/Chats"
import UserGoods from "@/views/User/Goods"
import UserRatings from "@/views/User/Ratings"
import UserTurnover from "@/views/User/Turnover"

import UserPayments from "@/views/User/Payments"
import UserPaymentsIncoming from "@/views/User/Payments/Incoming"
import UserPaymentsOutgoing from "@/views/User/Payments/Outgoing"

import UserSubscribes from "@/views/User/Subscribes"
import UserSubscribesCompany from "@/views/User/Subscribes/Company"
import UserSubscribesProducts from "@/views/User/Subscribes/Products"

import UserLots from "@/views/User/Lots"
import UserLotsBuy from "@/views/User/Lots/Buy"
import UserLotsSell from "@/views/User/Lots/Sell"

import Lots from "@/views/Lots"
import LotsBuy from "@/views/Lots/Buy"
import LotsSell from "@/views/Lots/Sell"
import LotsNew from "@/views/Lots/New"
import LotsOpened from "@/views/Lots/Opened"
import LotsClosed from "@/views/Lots/Closed"
import LotsCanceled from "@/views/Lots/Canceled"
import LotSingle from "@/views/LotSingle"

import Chats from "@/views/Chats"
import ChatsActive from "@/views/Chats/Active"
import ChatsClosed from "@/views/Chats/Closed"
import ChatSingle from "@/views/ChatSingle"

import Ratings from "@/views/Ratings"
import RatingsСhecking from "@/views/Ratings/Checking"
import RatingsConfirmed from "@/views/Ratings/Confirmed"
import RatingsCanceled from "@/views/Ratings/Canceled"

import Category from "@/views/Category"
import CategoryList from "@/views/Category/CategoryList"
import NewCategory from "@/views/Category/NewCategory"
import EditCategory from "@/views/Category/EditCategory"
import NewSubCat from "@/views/Category/NewSubCat"
import EditSubCat from "@/views/Category/EditSubCat"
import NewVolume from "@/views/Category/NewVolume"

import Payments from "@/views/Payments"
import Analytics from "@/views/Analytics"

import Documents from "@/views/Documents"
import DocsSignature from "@/views/Docs/Signature"
import DocsInformation from "@/views/Docs/Information"
import DocsInstruction from "@/views/Docs/Instruction"
import DocsNew from "@/views/Docs/New"
import DocsEdit from "@/views/Docs/Edit"

import Goods from "@/views/Goods"

import Registry from "@/views/Registry"

import Proxy from "@/views/Proxy"

import Promocode from "@/views/Promocode"

import Security from "@/views/Security"
import SecurityList from "@/views/Security/List"
import SecurityEdit from "@/views/Security/Edit"

import store from "@/store";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    redirect: {
        name: "UsersList"
    },
    meta: {
        isAuth: true
    }
},
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/add-admin",
        name: "AddAdmin",
        component: AddAdmin,
        meta: {
            isAuth: true
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        redirect: {
            name: "UsersList"
        },
        meta: {
            isAuth: true
        },
        children: [{
            path: "",
            name: "UsersList",
            component: UsersList,
            meta: {
                isAuth: true
            },
        },
            {
                path: "for-delete",
                name: "UsersForDelete",
                component: UsersForDelete,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: ":id",
                name: "User",
                component: UserSingle,
                redirect: {
                    name: "UserAbout"
                },
                children: [{
                    path: "about",
                    name: "UserAbout",
                    component: UserAbout,
                    meta: {
                        isAuth: true,
                    },
                },
                    {
                        path: "employees",
                        name: "UserEmployees",
                        component: UserEmployees,
                        redirect: {
                            name: "UserEmployeesList"
                        },
                        meta: {
                            isAuth: true,
                        },
                        children: [{
                            path: "",
                            name: "UserEmployeesList",
                            component: UserEmployeesList,
                            meta: {
                                isAuth: true,
                            }
                        },
                            {
                                path: ":employeeId",
                                name: "UserEmployeesItem",
                                component: UserEmployeesItem,
                                meta: {
                                    isAuth: true,
                                }
                            },
                            {
                                path: ":employeeId/counterparties",
                                name: "UserEmployeesCounterparties",
                                component: UserEmployeesCounterparties,
                                meta: {
                                    isAuth: true,
                                }
                            },
                            {
                                path: ":employeeId/proxy",
                                name: "UserEmployeesProxy",
                                component: UserEmployeesProxy,
                                meta: {
                                    isAuth: true,
                                }
                            },
                        ]
                    },
                    {
                        path: "counterparties",
                        name: "UserCounterparties",
                        component: UserCounterparties,
                        meta: {
                            isAuth: true,
                        }
                    },
                    {
                        path: "subscribes",
                        name: "UserSubscribes",
                        component: UserSubscribes,
                        redirect: {
                            name: "UserSubscribesProducts"
                        },
                        meta: {
                            isAuth: true,
                        },
                        children: [{
                            path: "products",
                            name: "UserSubscribesProducts",
                            component: UserSubscribesProducts,
                            meta: {
                                isAuth: true,
                            },
                        },
                            {
                                path: "company",
                                name: "UserSubscribesCompany",
                                component: UserSubscribesCompany,
                                meta: {
                                    isAuth: true,
                                },
                            },
                        ]
                    },
                    {
                        path: "chats",
                        name: "UserChats",
                        component: UserChats,
                        redirect: {
                            name: "UserChatsActive"
                        },
                        meta: {
                            isAuth: true,
                        },
                        children: [{
                            path: "active",
                            name: "UserChatsActive",
                            component: ChatsActive,
                            meta: {
                                isAuth: true,
                            },
                        },
                            {
                                path: "closed",
                                name: "UserChatsClosed",
                                component: ChatsClosed,
                                meta: {
                                    isAuth: true,
                                },
                            },
                        ]
                    },
                    {
                        path: "lots",
                        name: "UserLots",
                        component: UserLots,
                        redirect: {
                            name: "UserLotsBuy"
                        },
                        meta: {
                            isAuth: true,
                        },
                        children: [{
                            path: "buy",
                            name: "UserLotsBuy",
                            component: UserLotsBuy,
                            redirect: {
                                name: "UserLotsBuyNew"
                            },
                            meta: {
                                isAuth: true,
                            },
                            children: [{
                                path: "new",
                                name: "UserLotsBuyNew",
                                component: LotsNew,
                                meta: {
                                    isAuth: true,
                                },
                            },
                                {
                                    path: "opened",
                                    name: "UserLotsBuyOpened",
                                    component: LotsOpened,
                                    meta: {
                                        isAuth: true,
                                    },
                                },
                                {
                                    path: "closed",
                                    name: "UserLotsBuyClosed",
                                    component: LotsClosed,
                                    meta: {
                                        isAuth: true,
                                    },
                                },
                                {
                                    path: "canceled",
                                    name: "UserLotsBuyCanceled",
                                    component: LotsCanceled,
                                    meta: {
                                        isAuth: true,
                                    },
                                },
                            ]
                        },
                            {
                                path: "sell",
                                name: "UserLotsSell",
                                component: UserLotsSell,
                                redirect: {
                                    name: "UserLotsSellNew"
                                },
                                meta: {
                                    isAuth: true,
                                },
                                children: [{
                                    path: "new",
                                    name: "UserLotsSellNew",
                                    component: LotsNew,
                                    meta: {
                                        isAuth: true,
                                    },
                                },
                                    {
                                        path: "opened",
                                        name: "UserLotsSellOpened",
                                        component: LotsOpened,
                                        meta: {
                                            isAuth: true,
                                        },
                                    },
                                    {
                                        path: "closed",
                                        name: "UserLotsSellClosed",
                                        component: LotsClosed,
                                        meta: {
                                            isAuth: true,
                                        },
                                    },
                                    {
                                        path: "canceled",
                                        name: "UserLotsSellCanceled",
                                        component: LotsCanceled,
                                        meta: {
                                            isAuth: true,
                                        },
                                    },
                                    {
                                        path: ":slug/:lot",
                                        name: "UserLotSingleSell",
                                        component: LotSingle,
                                        meta: {
                                            isAuth: true,
                                        },
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: "payments",
                        name: "UserPayments",
                        component: UserPayments,
                        redirect: {
                            name: "UserPaymentsIncoming"
                        },
                        meta: {
                            isAuth: true,
                        },
                        children: [{
                            path: "outgoing",
                            name: "UserPaymentsOutgoing",
                            component: UserPaymentsOutgoing,
                            meta: {
                                isAuth: true,
                            },
                        },
                            {
                                path: "incoming",
                                name: "UserPaymentsIncoming",
                                component: UserPaymentsIncoming,
                                meta: {
                                    isAuth: true,
                                },
                            }
                        ]
                    },
                    {
                        path: "rating",
                        name: "UserRatings",
                        component: UserRatings,
                        meta: {
                            isAuth: true,
                        },
                    },
                    {
                        path: "goods",
                        name: "UserGoods",
                        component: UserGoods,
                        meta: {
                            isAuth: true,
                        },
                    },
                    {
                        path: "turnover",
                        name: "UserTurnover",
                        component: UserTurnover,
                        meta: {
                            isAuth: true,
                        },
                    },
                ]
            },
        ]
    },
    {
        path: "/lots",
        name: "Lots",
        component: Lots,
        redirect: {
            name: "LotsBuy"
        },
        meta: {
            isAuth: true,
        },
        children: [{
            path: "buy",
            name: "LotsBuy",
            component: LotsBuy,
            redirect: {
                name: "LotsBuyNew"
            },
            meta: {
                isAuth: true,
            },
            children: [{
                path: "new",
                name: "LotsBuyNew",
                component: LotsNew,
                meta: {
                    isAuth: true,
                },
            },
                {
                    path: "opened",
                    name: "LotsBuyOpened",
                    component: LotsOpened,
                    meta: {
                        isAuth: true,
                    },
                },
                {
                    path: "closed",
                    name: "LotsBuyClosed",
                    component: LotsClosed,
                    meta: {
                        isAuth: true,
                    },
                },
                {
                    path: "canceled",
                    name: "LotsBuyCanceled",
                    component: LotsCanceled,
                    meta: {
                        isAuth: true,
                    },
                },
            ]
        },
            {
                path: "sell",
                name: "LotsSell",
                component: LotsSell,
                redirect: {
                    name: "LotsSellNew"
                },
                meta: {
                    isAuth: true,
                },
                children: [{
                    path: "new",
                    name: "LotsSellNew",
                    component: LotsNew,
                    meta: {
                        isAuth: true,
                    },
                },
                    {
                        path: "opened",
                        name: "LotsSellOpened",
                        component: LotsOpened,
                        meta: {
                            isAuth: true,
                        },
                    },
                    {
                        path: "closed",
                        name: "LotsSellClosed",
                        component: LotsClosed,
                        meta: {
                            isAuth: true,
                        },
                    },
                    {
                        path: "canceled",
                        name: "LotsSellCanceled",
                        component: LotsCanceled,
                        meta: {
                            isAuth: true,
                        },
                    },
                ]
            },
        ]
    },
    {
        path: "/single-lot/:lot",
        name: "LotSingle",
        component: LotSingle,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/category",
        name: "Category",
        component: Category,
        meta: {
            isAuth: true
        },
        redirect: {
            name: "CategoryList"
        },
        children: [{
            path: "",
            name: "CategoryList",
            component: CategoryList,
            meta: {
                isAuth: true
            },
        },
            {
                path: "new-category",
                name: "NewCategory",
                component: NewCategory,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "edit-category/:catId",
                name: "EditCategory",
                component: EditCategory,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: ":catId/new-subcategory",
                name: "NewSubCat",
                component: NewSubCat,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: ":catId/edit-subcategory/:subcat_1_id?/:subcat_2_id?/:subcat_3_id?/:subcat_4_id?",
                name: "EditSubCat",
                component: EditSubCat,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "new-volume",
                name: "NewVolume",
                component: NewVolume,
                meta: {
                    isAuth: true,
                },
            }
        ]
    },
    {
        path: "/chats",
        name: "Chats",
        component: Chats,
        redirect: {
            name: "ChatsActive"
        },
        meta: {
            isAuth: true,
        },
        children: [{
            path: "active",
            name: "ChatsActive",
            component: ChatsActive,
            meta: {
                isAuth: true,
            },
        },
            {
                path: "closed",
                name: "ChatsClosed",
                component: ChatsClosed,
                meta: {
                    isAuth: true,
                },
            },
        ]
    },
    {
        path: "/chat/:chatId/:chatNumber",
        name: "ChatSingle",
        component: ChatSingle,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/payments",
        name: "Payments",
        component: Payments,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/analytics",
        name: "Analytics",
        component: Analytics,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/documents",
        name: "Documents",
        component: Documents,
        meta: {
            isAuth: true,
        },
        redirect: {
            name: "DocsSignature"
        },
        children: [{
            path: "signature",
            name: "DocsSignature",
            component: DocsSignature,
            meta: {
                isAuth: true,
            },
        },
            {
                path: "information",
                name: "DocsInformation",
                component: DocsInformation,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "instruction",
                name: "DocsInstruction",
                component: DocsInstruction,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "new/:docKind",
                name: "DocsNew",
                component: DocsNew,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "edit/:docKind/:docId",
                name: "DocsEdit",
                component: DocsEdit,
                meta: {
                    isAuth: true,
                },
            }
        ]
    },
    {
        path: "/ratings",
        name: "Ratings",
        component: Ratings,
        meta: {
            isAuth: true,
        },
        redirect: {
            name: "RatingsСhecking"
        },
        children: [{
            path: "checking",
            name: "RatingsСhecking",
            component: RatingsСhecking,
            meta: {
                isAuth: true,
            },
        },
            {
                path: "confirmed",
                name: "RatingsConfirmed",
                component: RatingsConfirmed,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "canceled",
                name: "RatingsCanceled",
                component: RatingsCanceled,
                meta: {
                    isAuth: true,
                },
            },
        ]
    },
    {
        path: "/goods",
        name: "Goods",
        component: Goods,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/registry",
        name: "Registry",
        component: Registry,
        meta: {
            isAuth: true,
        },
        redirect: {
            name: "RegistryProxy"
        },
        children: [{
            path: "photo",
            name: "RegistryPhoto",
            meta: {
                isAuth: true,
            },
        },
            {
                path: "video",
                name: "RegistryVideo",
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "docs",
                name: "RegistryDocs",
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "payments",
                name: "RegistryPayment",
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "deals",
                name: "RegistryDeals",
                meta: {
                    isAuth: true,
                },
            },
        ]
    },
    {
        path: "/registry/proxy",
        name: "RegistryProxy",
        component: Proxy,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/promocode",
        name: "Promocode",
        component: Promocode,
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/security",
        name: "Security",
        component: Security,
        redirect: {
            name: "SecurityList"
        },
        meta: {
            isAuth: true,
        },
        children: [
            {
                path: "",
                name: "SecurityList",
                component: SecurityList,
                meta: {
                    isAuth: true,
                },
            },
            {
                path: "edit/:id",
                name: "SecurityEdit",
                component: SecurityEdit,
                meta: {
                    isAuth: true,
                },
            }]
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.isAuth !== undefined) {
        if (store.state.isAuth) {
            next();
        } else {
            next({
                name: "Login"
            });
        }
    } else if (store.state.isAuth && (from != null || from?.name !== 'Login')) {
        next({
            name: "Home"
        });
    } else {
        next();
    }
});

export default router;
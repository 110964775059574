import store from "./index";
import axios from 'axios'

export default {
    state: {
        payments: null,
        paymentsInfo: {}
    },
    getters: {
        getPayments(state) {
            return state.payments
        },
    },
    mutations: {
        setPayments(state, payments) {
            state.payments = payments
        },
        
    },
    actions: {
        uploadPayments({ commit, state }, paymentsInfo) {
            state.paymentsInfo = paymentsInfo
            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/payments?search=${paymentsInfo.search}`

            if (paymentsInfo.userId) {
                    paymentsInfo.route == 'UserPaymentsIncoming' 
                        ? url += `&payer=${paymentsInfo.userId}`
                        : url += `&payee=${paymentsInfo.userId}`
            }
            if (paymentsInfo.offset) url += `&${paymentsInfo.offset}`

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
                }).then(response => {
                    let _allPayments = state.payments
                    if (paymentsInfo.offset && _allPayments) {
                        response.data.forEach(elem => {
                            _allPayments.push(elem)
                        })
                        commit('setPayments', _allPayments)
                    } else {
                        commit('setPayments', response.data)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.dispatch('userLogout')
                    } else if (error.response.status === 403) {
	                    commit('setErrorText', 'Нет прав для просмотра данного раздела')
	                    setTimeout(() => {
	                        window.location.href = '/'
	                    }, 1500)
                	} else {
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        },
        executePayment({ commit, state }, paymentsInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/payments/${paymentsInfo.paymentId}/execute`, {}, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    commit('setErrorText', 'Платеж изменен')
                    state.payments = null
                    this.dispatch('uploadPayments', paymentsInfo)
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    }else{
                        console.error(error)
                    }     
                })
        },
        refundPayment({ commit, state }, paymentsInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/payments/${paymentsInfo.paymentId}/refund`, {},  {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setErrorText', 'Платеж изменен')
                    state.payments = null
                    this.dispatch('uploadPayments', paymentsInfo)
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    }else{
                        console.error(error)
                    }     
                })
        },
    },
}
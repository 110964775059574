import store from "./index";
import axios from 'axios'

export default {
    state: {
        allSecurity: null,
        loading: false,
        totalCount: 0,
        allSupervisors: [],
        singleSecurity: null,
        queryObject: {
            searchTxt: ''
        },
    },
    getters: {
        getAllSupervisors(state) {
            return state.allSupervisors
        },
        getAllSecurity(state) {
            return state.allSecurity
        },
        getSingleSecurity(state) {
            return state.singleSecurity
        },
        getSecLoading(state) {
            return state.loading
        },
        getSecTotalCount(state) {
            return state.totalCount
        }
    },
    mutations: {
        setAllSupervisors(state, allSupervisors) {
            state.allSupervisors = allSupervisors
        },
        setAllSecurity(state, allSecurity) {
            state.allSecurity = allSecurity
        },
        setSingleSecurity(state, singleSecurity) {
            state.singleSecurity = singleSecurity
        },
        setSecLoading(state, loading) {
            state.loading = !!loading
        },
        setSecTotalCount(state, totalCount) {
            state.totalCount = totalCount
        },
    },
    actions: {
        clearSupervisors({commit, state}) {
            commit('setAllSupervisors', [])
        },
        fetchSupervisors({commit, state}, payload) {
            let {offset, search} = payload || {};
            let params = {
                limit: 10
            }
            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/`

            if (search) {
                params['search'] = search
            }
            if (offset) {
                params['offset'] = offset
            }

            commit('setLoading', true)
            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                params
            }).then(response => {
                let _allUsers = state.allSupervisors

                if (JSON.stringify(_allUsers) === JSON.stringify(response.data)) return

                if (_allUsers) {
                    response.data.forEach(elem => {
                        _allUsers = _allUsers.filter(e => e.id !== elem.id)
                        _allUsers.push(elem)
                    })
                    commit('setAllSupervisors', _allUsers)
                } else {
                    commit('setAllSupervisors', response.data)
                }
                commit('setSecTotalCount', response.headers['total-count'])
                commit('setSecLoading', false)
            }).catch(error => {
                console.log(error)
                commit('setSecLoading', false)
            })
        },
        uploadSecurity({commit, state}, queryOption) {
            state.queryObject = queryOption
            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security?search=${queryOption.searchTxt}`
            if (queryOption.supervisor) url += queryOption.supervisor

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setAllSecurity', response.data)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    this.dispatch('userLogout')
                } else {
                    commit('setErrorText', 'Ошибка соединения с сервером')
                }
            })
        },
        uploadSingleSecurity({commit, state}, securityId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security/${securityId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setSingleSecurity', response.data)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    this.dispatch('userLogout')
                } else {
                    commit('setErrorText', 'Ошибка соединения с сервером')
                }
            })
        },
        addSecurity({commit, state}, securityInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security`, securityInfo, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('uploadSecurity', state.queryObject)
                commit('setErrorText', 'Создан сотрудник СБ')
            }).catch(error => {
                if (error.response) {
                    const errorText = error.response.status === 499 ? error.response.data.errors : error.response.request.responseText
                    commit('setErrorText', errorText)
                }
            })
        },
        editSecurity({commit, state}, {securityId, securityInfo}) {
            return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security/${securityId}`, securityInfo, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('uploadSecurity', state.queryObject)
            }).catch(error => {
                if (error.response) {
                    const errorText = error.response.status === 499 ? error.response.data.errors : error.response.request.responseText
                    commit('setErrorText', errorText)
                }
            })
        },
        toggleSecurityActivity({commit, state}, data) {
            let url = data.action === 'block' ?
                `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security/${data.id}/block` :
                `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/security/${data.id}/unblock`

            return axios.post(url, {}, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('uploadSecurity', state.queryObject)
            }).catch(error => {
                if (error.response) {
                    const errorText = error.response.status === 499 ? error.response.data.errors : error.response.request.responseText
                    commit('setErrorText', errorText)
                }
            })
        },
    }
}
<template>
  <div class="table__tr">
    <!--    <div class="table__td">-->
    <!--      <p class="text-grey">-->
    <!--        {{ item.title }}-->
    <!--      </p>-->
    <!--    </div>-->
    <div class="table__td">
      <div @click="getPrivateDoc(item)" class="bold-green">
        {{ item.title ? item.title : item.fileInitialName }}
      </div>
    </div>
    <div class="table__td">
      <p class="text-grey">
        {{ Math.floor(new Date(+item.signedAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        в
        {{ Math.floor(new Date(+item.signedAt).getTime() / 1000.0) | moment("HH:mm") }}
      </p>
    </div>
    <div class="table__td">
      <div class="c-payment__company">
        <div class="c-payment__profile" v-if="item.approvedSender">
          <router-link :to="({ name: 'User', params: { id: supervisorOrOwnId(item.approvedSender)} })"
                       class="c-payment__profile-avatar">
            <img :src="supervisorOrOwnAvatar(item.approvedSender).file" alt="avatar" class="avatar"
                 v-if="supervisorOrOwnAvatar(item.approvedSender)">
            <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
          </router-link>
          <div class="c-payment__info">
            <div class="c-payment__profile-title">
              <router-link :to="({ name: 'User', params: { id: supervisorOrOwnId(item.approvedSender)} })">
                {{ supervisorOrOwnName(item.approvedSender) }}
              </router-link>
            </div>
            <div class="c-payment__profile-role">
              <p> ИНН {{ supervisorOrOwnInn(item.approvedSender) }} </p>
              <p> {{
                  item.approvedSender.organizationType !== organizationTypesEnum['Физлицо']
                      ? supervisorOrOwnFio(item.approvedSender)
                      : ''
                }} </p>
            </div>
          </div>
        </div>
        <div class="c-payment__profile" v-if="item.approvedReceiver">
          <router-link :to="({ name: 'User', params: { id: supervisorOrOwnId(item.approvedReceiver)} })"
                       class="c-payment__profile-avatar">
            <img :src="supervisorOrOwnAvatar(item.approvedReceiver).file" alt="avatar" class="avatar"
                 v-if="supervisorOrOwnAvatar(item.approvedReceiver)">
            <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
          </router-link>
          <div class="c-payment__info">
            <div class="c-payment__profile-title">
              <router-link :to="({ name: 'User', params: { id: supervisorOrOwnId(item.approvedReceiver)} })">
                {{ supervisorOrOwnName(item.approvedReceiver) }}
              </router-link>
            </div>
            <div class="c-payment__profile-role">
              <p> ИНН {{ supervisorOrOwnInn(item.approvedReceiver) }} </p>
              <p> {{
                  item.approvedReceiver.organizationType !== organizationTypesEnum['Физлицо']
                      ? supervisorOrOwnFio(item.approvedReceiver)
                      : ''
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {organizationTypesEnum} from "../../enums";

export default {
  computed: {
    organizationTypesEnum() {
      return organizationTypesEnum
    }
  },
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      fileUrl: ''
    }
  },
  created() {
    // switch (this.$route.name) {
    //   case 'RegistryPhoto':
    //     this.getFileUrl('message_images', this.item.id)
    //     break
    //   case 'RegistryVideo':
    //     this.getFileUrl('message_videos', this.item.id)
    //     break
    //   case 'RegistryDocs':
    //     this.getFileUrl('message_documents', this.item.id)
    //     break
    //   case 'RegistryPayment':
    //     this.getFileUrl('payments', this.item.id)
    //     break
    //   case 'RegistryDeals':
    //     this.getFileUrl('conditions', this.item.id)
    //     break
    // }

  },
  methods: {
    isImage(mimeType) {
      return ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'image/svg+xml'].includes(mimeType)
    },
    getFio(firstName, middleName, lastName) {
      let _parts = [];
      _parts.push(lastName)
      _parts.push(firstName)
      _parts.push(middleName)

      return _parts.join(' ')
    },
    supervisorOrOwnFio(user) {
      if (user.supervisor) {
        return this.getFio(user.supervisor.firstName, user.supervisor.middleName, user.supervisor.lastName)
      } else {
        return this.getFio(user.firstName, user.middleName, user.lastName)
      }
    },
    supervisorOrOwnName(user) {
      if (user.supervisor) {
        return user.supervisor.name
      } else {
        return user.name
      }
    },
    supervisorOrOwnId(user) {
      if (user.supervisor) {
        return user.supervisor.id
      } else {
        return user.id
      }
    },
    supervisorOrOwnInn(user) {
      if (user.supervisor) {
        return user.supervisor.inn
      } else {
        return user.inn
      }
    },
    supervisorOrOwnAvatar(user) {
      if (user.supervisor) {
        return user.supervisor.avatar
      } else {
        return user.avatar
      }
    },
    async getPrivateDoc(doc) {
      // #53007
      return false;
      // #52890
      if (this.isImage(doc.mimeType)) {
        return false;
      }
      //
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/documents/signed/${doc.id}`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        let url = response.data.url
        window.open(url, '_blank');
      })
    },
    async getFileUrl(message_type, message_id) {
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/documents/signed/${message_type}/${message_id}/url`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        return this.fileUrl = response.data.url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table__td {
  align-items: center;
  justify-content: flex-start;
  padding: 3.6rem;

  &:nth-of-type(1) {
    width: 26.1rem;
  }

  &:nth-of-type(2) {
    width: 36.7rem;
  }

  &:nth-of-type(3) {
    width: 35.4rem;
  }

  &:last-child {
    width: 58.7rem;
  }

}
</style>